<template>
  <v-container class="plan-select-app">
    <woo-commerce-plans v-if="this.currentShop && this.currentShop.platform === 'woocommerce'"  :current-shop="this.currentShop" />
    <div v-else-if="currentActivePlan === null || currentShop === null || availablePlansList.length < 1">
      <div class="loading text-center" id="syncio-loading-logo">
        <loading-syncio-logo></loading-syncio-logo>
      </div>
    </div>
    <transition name="fade" v-else>
      <div v-if="currentActivePlan != null && availablePlansList.length > 0" >
        <BackToSettings />
        <v-row>
          <v-col class="d-flex flex-row justify-center">
            <svg v-if="isOnboarding" width="120px" height="30px" viewBox="0 0 100 30" style="transform: translateX(-6px);">
              <circle cx="10" cy="10" r="9" class="filled"/>
              <line x1="19" y1="10" x2="31" y2="10" class="line"/>
              <circle cx="40" cy="10" r="9" class="filled"/>
              <line x1="61" y1="10" x2="49" y2="10" class="line"/>
              <circle cx="70" cy="10" r="9" class="filled"/>
              <line x1="91" y1="10" x2="79" y2="10" class="line"/>
              <circle cx="100" cy="10" r="9" class="not-filled"/>
            </svg>
          </v-col>
        </v-row>
        <v-row class="mt-3">
          <v-col>
            <div class="notice">
              <div class="d-flex flex-column">
                <div class="text-h1 primary--text text-center">Manage Your Plan</div>
                <div class="text-body-2 text-center mt-1">
                  Upgrade or downgrade anytime. <a href="javascript:void(0);" class="intercom-custom-launcher text-decoration-none">Contact us</a> for help.
                </div>
              </div>
            </div>
          </v-col>
        </v-row>

        <!-- Step 1 -->
        <v-card class="card-rounded not-a-link px-10 pt-8 pb-10 mt-9">
          <v-row>
            <v-col>
              <div class="text--label--1 primary--text">STEP 1: BASE PLAN</div>
              <div class="text-subtitle-1 primary--text mt-2"> Select how many products you need to sync</div>
              <div class="text-caption mt-2">All base plans include real-time & ongoing inventory syncing. Learn more about our pricing <a href="https://www.syncio.co/pricing" class="text-decoration-none" target="_blank">here</a>.</div>
            </v-col>
          </v-row>

          <v-row class="px-0 pt-8 pb-5 mt-2" v-if="!currentActivePlan.syncio_plan.is_active && !isOnboarding">
            <v-col class="section-1">
              <div class="d-flex flex-column">
                <div class="text-center text--label--3">You're currently on a custom or legacy plan</div>
                <div class="text-caption-3 text-center mt-4">
                  We haven't made any changes to your account. Please consider whether one of our plans below would better suit your needs.
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row class="mt-6">
            <plan-desc-block
              v-for="(plan, index) in availablePlansList"
              v-bind:key="index"
              :plan="plan" />
          </v-row>
        </v-card>
        <!-- Step 1 -->

        <v-row class="mt-5">
          <v-col cols="8">
            <v-card class="card-rounded not-a-link px-10 py-8 mr-2" height="100%">
              <div class="text--label--1 primary--text">STEP 2: ADD-ONS</div>
              <div class="text-subtitle-1 primary--text mt-2">Select an add-on</div>
              <div class="text-caption mt-1">Optional. Upgrade or cancel anytime.</div>
              <v-row class="mt-4" v-if="addons != null">
                <addon-option
                  v-for="(addon, index) in addons"
                  v-bind:key="index"
                  :addon="addon"
                  :index="index" />
              </v-row>
            </v-card>
          </v-col>
          <v-col cols="4" v-if="currentActivePlan != null && selectedNewPlan != null && typeof selectedNewPlan.active_addons != 'undefined'">
            <plan-cart-summary />
          </v-col>
        </v-row>
      </div>
    </transition>

    <UpdateAddonStepTwo />
    <UpdateAddonStepThree />
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import PlanCartSummary from '@/views/plan/components/PlanCartSummary';
import PlanDescBlock from '@/views/plan/components/PlanDescBlock';
import AddonOption from '@/views/plan/components/AddonOption';
import WooCommercePlans from "@/views/plan/components/WooCommercePlans";
import LoadingSyncioLogo from "@/views/registration/components/LoadingSyncioLogo";
import UpdateAddonStepTwo from "@/components/UpdateAddonStepTwo.vue";
import UpdateAddonStepThree from "@/components/UpdateAddonStepThree.vue";
import { createHelpers } from "vuex-map-fields";
import JwtService from "@/common/jwt.service";
import BackToSettings from "@/views/settings/BackToSettings.vue";

const { mapFields } = createHelpers({
  getterType: 'auth/getField',
  mutationType: 'auth/updateField',
});

export default {
  name: "PlanSelectionPage",
  data: function() {
    return {
      addons: [
        {
          module_id: 2,
          title: "Product Settings",
          price_per_month: 19,
          description: "Syncio's <strong>Product Settings</strong> add-on allows you to sync specific product attributes such as tags on an ongoing basis.",
          external_link: "https://help.syncio.co/en/articles/3704617-product-settings-add-on"
        },
        {
          module_id: 1,
          title: "Orders",
          price_per_month: 22,
          description: "Syncio's <strong>Orders</strong> add-on can be found under the Orders link in the app.",
          external_link: "https://help.syncio.co/en/articles/4163480-orders-add-on"
        },
        {
          module_id: 3,
          title: "Payouts",
          price_per_month: 9,
          description: "Payouts requires Orders add-on to be turned on. Generate payouts to keep track of commissions and payments with connected stores (Shopify only).",
          external_link: "https://help.syncio.co/en/collections/3557007-payouts-shopify-only"
        }
      ],
    };
  },
  computed: {
    ...mapState('plan', ['availablePlansList', 'currentActivePlan', 'selectedNewPlan', 'storeType', 'isOnboarding', 'isPlanNull']),
    ...mapState('shop', ['currentShop']),
    ...mapState('auth', ['user_id', 'showAddonsDialog']),
    ...mapFields([
      "showUpdateAddonsDialogTwo",
    ]),
  },
  mounted: function() {
    if (this.currentShop == null) {
      this.$store.dispatch(`shop/init`).then(() => {
        this.$store.dispatch(`plan/init`, this.user_id);
      })
    } else {
      this.$store.dispatch(`plan/init`, this.user_id);
    }
  },
  components: {
    BackToSettings,
    WooCommercePlans,
    PlanCartSummary,
    PlanDescBlock,
    AddonOption,
    LoadingSyncioLogo,
    UpdateAddonStepTwo,
    UpdateAddonStepThree,
  },

  beforeRouteLeave (to, from, next) {
    if(this.showAddonsDialog && to.path !== '/logout') {
      this.showUpdateAddonsDialogTwo = true;
      next(false)
    } else {
      next()
    }
  },
}
</script>

<style lang="scss">
.plan-select-app {
  max-width: 1100px;
    a {
      text-decoration: underline;
    }

    h2 {
      text-align: center;
    }

    .text-caption {
      color: #495057;
    }

    .text-body-2 {
      color: #0E3B4D;
    }

    .top {
      margin-bottom: 20px;
      .notice {
        text-align: center;
        background-color: #e3f3ff;
        padding: 20px;
        u {
          color: #007ace;
          cursor: pointer;
        }
      }
    }
  }
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
  .intercom-custom-launcher {
    color: rgb(0, 93, 168);
  }
  #syncio-loading-logo {
    width: 100%;
    height: calc(100vh - 50px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .text--label--3 {
    font-style: normal !important;
    font-weight: 600;
    font-size: 18px !important;
    line-height: 22px !important;
    height: 22px;
    color: #123342;
  }
  .text-caption-3{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #123342;
  }
  .section-1{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 14px 75px;
    gap: 20px;

    height: 94px;
    background: #F59E0B;
    box-shadow: 0px 1.04731px 10.4731px rgba(0, 0, 0, 0.12), 0px 4.18925px 5.23656px rgba(0, 0, 0, 0.14), 0px 2.09462px 4.18925px -1.04731px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
  }
</style>
