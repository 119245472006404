<template>
  <v-container class="container-max-width">
    <v-row>
      <v-col cols="12" class="text-center">
        <div class="text-h1 primary--text">Please Wait</div>
        <div class="text-body-2 mt-3 primary--text"> Upgrade or downgrade anytime.
          <router-link to="#">Contact us</router-link>
          for help.
        </div>
      </v-col>
    </v-row>
    <v-row class="mt-9">
      <v-col cols="12" class="d-flex justify-center">
        <v-card class="rounded-lg pa-10" width="738px" height="460px">
          <v-row>
            <v-col>
              <div class="subtitle-1 text-center">
                Please wait whilst you are redirected to our payments platform
              </div>
              <div class="mt-6 d-flex flex-row align-center">
                <v-img height="191px" width="264px" contain src="@/assets/images/woo-plan.png">
                </v-img>
              </div>
            </v-col>
          </v-row>
          <v-row class="mt-3">
            <v-col cols="12" class="d-flex justify-center">
              <v-btn class="primary rounded-lg" height="48px" width="270px" @click="$router.push('/dashboard')">
                <div class="d-flex flex-row justify-center">
                  <div class="mr-3">
                    <v-icon>
                      arrow_back
                    </v-icon>
                  </div>
                  <div class="text-body-1">
                    Back to Dashboard
                  </div>
                </div>
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import JwtService from "@/common/jwt.service";

export default {
  name: "WooCommercePlans",
  props: ['currentShop'],
  mounted:  function () {
   console.log(this.currentShop)


    if (this.currentShop.type !== "source") {
      window.location.href = `${process.env.VUE_APP_WOO_BILLING}/?jwt=${JwtService.getToken()}`
    }
     this.$router.push("/dashboard")
  }
}
</script>

<style scoped>
.container-max-width {
  width: 1100px;
}
</style>