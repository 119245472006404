<template>
  <v-col cols="6">
    <v-card
        class="addon-wrapper card-rounded"
        :class="{ selected: isSelected }"
    >
      <div class="addon-wrapper__inner px-8 py-5" @click="!isDisabled && toggleAddon()">
        <div class="text--label--1 primary--text text-uppercase relative">
          {{ addon.title }}
          <ToggleButton
              :labels="{ 'checked': 'ON', 'unchecked': 'OFF' }"
              :width="55"
              :height="25"
              :value="isSelected"
              :disabled="isDisabled"
              :sync="true"
              class="absolute toggle-switch"/>
        </div>

        <div class="text-caption mt-4" style="line-height: 19px; font-size: 13px !important;">
          <span v-html="addon.description"></span>
          <a :href="addon.external_link" class="d-inline-block ml-1 text-decoration-none" @click.stop target="_blank">Learn more</a>
        </div>
        <div class="text-subtitle-2 color-navy-hover">
          + $<span>{{ addon.price_per_month | numFormat("0.00") }}</span> / month
        </div>
      </div>
    </v-card>
  </v-col>
</template>

<script>
import {ToggleButton} from 'vue-js-toggle-button';
import {mapState} from "vuex";
import $ from "jquery";

export default {
  props: ["addon", "index"],
  components: {
    ToggleButton
  },
  computed: {
    ...mapState("plan", ["selectedNewPlan"]),
    isSelected: function () {
      let moduleId = this.addon.module_id;
      let hasSelected = false;
      if (
          this.selectedNewPlan != null &&
          typeof this.selectedNewPlan.active_addons != "undefined"
      ) {
        $.each(this.selectedNewPlan.active_addons, (key, item) => {
          if (item != null && item.module_id === moduleId && item.selected) {
            hasSelected = true;
          }
        });
      }
      return hasSelected;
    },
    isDisabled: function () {
      let moduleId = this.addon.module_id;
      let orderAddon = null

      if(this.selectedNewPlan) {
        orderAddon = this.selectedNewPlan.active_addons.find((addon) => addon.module_id === 1)
      }

      return moduleId === 3 && (orderAddon && !orderAddon.selected);
    }
  },
  methods: {
    toggleAddon: function () {
      if (this.selectedNewPlan != null) {
        let moduleId = this.addon.module_id;
        let hasAddon = false;
        $.each(this.selectedNewPlan.active_addons, (index, item) => {
          if (
              item.module_id === moduleId &&
              typeof item.selected != "undefined"
          ) {
            this.selectedNewPlan.active_addons[index].selected = !item.selected;
            hasAddon = true;
            return;
          } else if (
              item.module_id === moduleId &&
              typeof item.selected === "undefined"
          ) {
            this.selectedNewPlan.active_addons[index].selected = true;
            hasAddon = true;
            return;
          }
        });
        if (!hasAddon) {
          let newAddon = Object.assign({}, this.addon);
          newAddon.selected = true;
          this.selectedNewPlan.active_addons.push(newAddon);
        }

        const orderAddon = this.selectedNewPlan.active_addons.find((addon) => addon.module_id === 1)

        if (orderAddon && orderAddon.selected === false) {
          const payoutAddon = this.selectedNewPlan.active_addons.find((addon) => addon.module_id === 3)
          if(payoutAddon) {
            payoutAddon.selected = false
          }
        }

        this.$store.commit("plan/SET_SELECTED_NEW_PLAN", this.selectedNewPlan);
      }
    },
  },
};
</script>

<style lang="scss">
.color-grey {
  color: #495057;
}

.color-navy-hover {
  color: #145b78;
}

.addon-wrapper {
  background: #f4f4f4 !important;
  border-color: transparent !important;

  &__inner {
    min-height: 188px;
    cursor: pointer;
    position: relative;

    .text-subtitle-2 {
      position: absolute;
      left: 32px;
      bottom: 20px;
    }
  }

  &:hover,
  &.selected {
    border-color: #539dbd !important;
  }

  &.selected {
    .v-switch-core {
      background: #0E3B4D !important;
    }
  }

  .available-addon {
    .btn-wrapper {
      .button {
        float: right;
      }
    }
  }

  .check-svg {
    right: 13px;
    top: 20px;
  }

  .text-caption {
    color: #000 !important;
    font-weight: 400 !important;
  }

  .toggle-switch {
    right: -10px;
    top: -4px;
    pointer-events: none;
  }
}
</style>
