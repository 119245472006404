<template>
  <v-col cols="2" class="plan-desc-block" v-if="plan != null" @click="setPlan(plan.id)">
    <div :class="{ 'current' :  (isCurrentPlan && !isOnboarding) }">
		<div class="header" v-if="isCurrentPlan">CURRENT PLAN</div>
			<v-card class="text-left pa-4 pb-6 card-rounded select-plan-box" :class="{ 'selected': isSelected }">
				<input
					class="radio-input d-none"
					type="radio"
					:checked="(selectedNewPlan != null && selectedNewPlan.id === plan.id) || isSelected"
				/>
				<svg class="absolute check-svg" v-if="(selectedNewPlan != null && selectedNewPlan.id === plan.id) || isSelected" width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    			<path fill-rule="evenodd" clip-rule="evenodd" d="M8.31226 0.555664C3.85511 0.555664 0.312256 4.09852 0.312256 8.55566C0.312256 13.0128 3.85511 16.5557 8.31226 16.5557C12.7694 16.5557 16.3123 13.0128 16.3123 8.55566C16.3123 4.09852 12.7694 0.555664 8.31226 0.555664ZM7.16941 11.9842L4.08369 8.89851L5.11226 7.98423L7.16941 10.0414L11.7408 5.58423L12.7694 6.49851L7.16941 11.9842Z" fill="#0E3B4D"/>
				</svg>
				<div class="mb-2 text-uppercase text--label--1 font-weight-bold">
					{{ plan.name }}
				</div>
				<div class="description ">
					<div>
						<div class="text-subtitle-1">{{ plan.sync_product_limit }}</div>
						<div class="text-caption text-lowercase">Products</div></div>
					</div>
					<div>
					<h5 class="text-subtitle-2 primary--text mt-3" v-if="plan.price_per_month != 0.0">
						{{ plan.price_per_month | numFormat("$0") }} / month
					</h5>
				</div>
			</v-card>
		</div>
  </v-col>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
	name: "PlanDescBlock",
	props: ['plan'],
	computed: {
		...mapState('plan',[ 'selectedNewPlan', 'currentActivePlan']),
		...mapGetters('plan', ['isOnboarding']),
		...mapState("shop", ["currentShop"]),
		isCurrentPlan: function() {
			if (this.currentActivePlan != null) {
				if (this.isOnboarding && this.currentShop.type != 'source') {
					return true;
				}
				return this.currentActivePlan.syncio_plan.id === this.plan.id;
			} else {
				return false;
			}
		},
		isSelected: function() {
			if (this.selectedNewPlan != null && this.selectedNewPlan.id != undefined) {
				return this.selectedNewPlan.id === this.plan.id;
			} else if (this.currentActivePlan != null) {
				return this.currentActivePlan.syncio_plan.id === this.plan.id;
			} else {
				return false;
			}
		}
	},
	methods: {
		setPlan: function(planId) {
			this.$store.commit('plan/SET_SELECTED_NEW_PLAN_BY_ID', planId);
		},
	}
}
</script>

<style lang="scss">
	.select-plan-box {
		background: #F4F4F4 !important;
		border-color: transparent;
	}

	.cost {
		color: #145b78 !important;
	}

  .plan-desc-block {
    position: relative;
    align-self: flex-end;
    cursor: pointer;

		.text--label--1 {
			color: #495057;
			line-height: 20px;
		}

		.text-subtitle-1 {
			color: #212429;
		}

		.text-caption {
			color: #212429;
			letter-spacing: .2px;
		}

		.text-subtitle-2 {
			color: #145B78 !important;
		}

		.check-svg {
			top: 16px;
			right: 14px;
		}

    &.column {
      &.two {
      	max-width: 155px;
      }
    }

    .selected {
      border: #539dbd 2px solid;
      box-sizing: border-box;
    }

    .name {
      color: #495057;
    }

    .radio-input {
    	position: absolute;
      height: 15px;
      width: 15px;
      color: #5c6ac4;
      right: 10px;
    }

    .card {
      h3 {
        margin-top:10px;
      }

      .description {
        .features {
					text-align: left;
					font-size: 10px;
					margin-bottom: 15px;

          > div {
						i {
							color: #2f61ec;
							margin-right: 5px;
						}
          }
        }
    	}

			small {
				font-size: 12px;
				font-weight: 500;
			}
    }

		.header {
			visibility: hidden;
			color: #fff;
			background-color: #539dbd;
			text-align: center;
			padding: 5px;
			pointer-events: none;
		}

		.current {
			.header {
				visibility: visible;
				border-radius: 10px 10px 0px 0px;
			}

			> .card,
			> .v-card {
				border: #539dbd 2px solid;
				border-top-left-radius: 0 !important;
				border-top-right-radius: 0 !important;
			}
		}
  }
</style>
