<script>
import { createHelpers } from "vuex-map-fields";
import InformationDialog from './InformationDialog.vue';

const { mapFields } = createHelpers({
  getterType: 'auth/getField',
  mutationType: 'auth/updateField',
});

export default {
  name: 'UpdateAddonStepTwo',

  components: {
    InformationDialog
  },

  computed: {
    ...mapFields([
      "showUpdateAddonsDialogTwo",
      "showUpdateAddonsDialogThree"
    ]),
  },

  methods: {
    updateHandler() {
      this.showUpdateAddonsDialogTwo = false;
    },

    closeUpgradeDailog() {
      this.showUpdateAddonsDialogTwo = false;
      this.showUpdateAddonsDialogThree = true;
    }
  }
}
</script>

<template>
  <InformationDialog width="525px" title="Update your Add-on Plan" :showDialog="showUpdateAddonsDialogTwo">
    <template #closeDialog>
      <v-icon size="30px" color="#ffffff" class="mt-1 pointer" @click="closeUpgradeDailog">close</v-icon>
    </template>
    <p class="mb-5">To update your Syncio add-ons please follow these steps:</p>
    <p class="mb-2">1. Review your current plan and add-ons.</p>
    <p class="mb-2">2. Click on 'Next'.</p>
    <p class="mb-2">3. You will then be redirected to  Shopify.</p>
    <p class="mb-5">4. Click on 'Approve' to confirm the plan update.</p>
    <v-btn height="48px" class="btn-primary mt-3 px-5" elevation="0" width="200px" @click="updateHandler">Continue</v-btn>
  </InformationDialog>
</template>
