<template>
  <v-card class="plan-cart-summary pa-5 py-7 card-rounded not-a-link" height="100%">
  	<div class="text-subtitle-1 primary--text">You have selected:</div>
		<div v-if="currentActivePlan != null">
			<div class="base plan">
				<div class="text--label--1 mt-6 mb-2 black-light">BASE PLAN</div>
				<template v-if="!isOnboarding && currentActivePlan.syncio_plan.id !== selectedNewPlan.id">
					<table class="mt-2 color-navy-hover" v-if="currentActivePlan.syncio_plan.id != 88">
						<tr :class='{"cross-text": (selectedNewPlan != null && selectedNewPlan.id != undefined && selectedNewPlan.id != currentActivePlan.syncio_plan.id)}'>
							<td class="plan-name color-navy-hover">{{ currentActivePlan.name }}</td>
							<td class="plan-summary-price">
								{{ currentActivePlan.syncio_plan.price_per_month | numFormat("$0.00") }}
							</td>
						</tr>
					</table>
				</template>
				<table>
					<tr class="mt-5 color-navy-hover" v-if="(selectedNewPlan != null && selectedNewPlan.id != null)">
						<td class="plan-name">
							{{ selectedNewPlan.name }}
						</td>
						<td class="plan-summary-price">
							{{ selectedNewPlan.price_per_month | numFormat("$0.00") }}
						</td>
					</tr>
					<tr class="mt-5 color-navy-hover" v-else-if="(currentActivePlan.syncio_plan.id === 88)">
						<td class="plan-name">
							Please select a new plan
						</td>
						<td>
						</td>
					</tr>
				</table>
			</div>
			<div class="extra plan">
				<div class="text--label--1 mt-6 mb-2 black-light">ADD-ONS</div>
				<table class="current" v-if="currentActivePlan.active_addons != null && currentActivePlan.active_addons.length">
					<tr class="mt-5 color-navy-hover"
						:class='{"cross-text": shouldCrossOutOldAddonLineItem(feature)}'
						v-for="(feature, index) in currentActivePlan.active_addons" v-bind:key="index">
						<td class="plan-name">
							{{ feature.name }}
						</td>
						<td class="plan-summary-price">
							{{ feature.price_per_month | numFormat("$0.00") }}
						</td>
					</tr>
				</table>
				<table class="empty" v-else-if="selectedNewPlan.active_addons === null || selectedNewPlan.active_addons.length < 1 || allSelectedAddonOff">
					<tr class="mt-5 color-navy-hover">
						<td class="plan-name">
							None
						</td>
						<td>
						</td>
					</tr>
				</table>
				<table class="new" v-if="selectedNewPlan != null">
					<tr class="mt-5 color-navy-hover" v-for="(feature, index) in selectedNewPlan.active_addons" v-bind:key="index"
						:class='{"hide": shouldHideNewAddonLineItem(feature)}'>
						<td v-if="feature != null && feature.selected" class="plan-name">
							{{ feature.title }}
						</td>
						<td class="plan-summary-price" v-if="feature != null && feature.selected">
							{{ feature.price_per_month | numFormat("$0.00") }}
						</td>
					</tr>
				</table>
			</div>
			<hr>
			<div class="total-price mt-2">
				<table>
					<tr>
						<td class="plan-name current_total black-light pt-1">
							<span>TOTAL:</span>
						</td>
						<td class="text-h6 my-0 plan-summary-price total-price color-navy-hover text-right">
							<span>{{ totalPrice | numFormat("$0.00") }} </span>
							<span>/ month</span>
						</td>
					</tr>
				</table>
			</div>
		</div>
		<div class="submit-btn">
			<v-btn
				block
				:class="isAllowedToProceedToPayment"
				class="mt-5 primary "
				height="48px"
				@click="submitNewPlan()">
					Next <v-icon class="ml-1 right-arrow">mdi-arrow-right</v-icon>
			</v-btn>
		</div>
	</v-card>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import UserApiService from "@/common/api/user.service";
import $ from "jquery";
import _ from 'lodash';

export default {
    name: "PlanCartSummary",
    data: function() {
      return {
        csrfToken: null,
      };
    },
    computed: {
        ...mapState('plan',['selectedNewPlan', 'currentActivePlan']),
        ...mapGetters('plan', ['isOnboarding']),
        ...mapState("shop", ["currentShop"]),
				...mapState('auth', ['user_id', 'showAddonsDialog']),
        isAllowedToProceedToPayment: function() {
					// if(this.selectedNewPlan.id === 88){
					//     return ''; // edge case
					// } todo: @fe look into this
					if(!this.showAddonsDialog && (this.selectedNewPlan === null || (this.selectedNewPlan != null && typeof(this.selectedNewPlan.id) === 'undefined'))) {
						return 'disable-events';
					}

          var selectedPlanActiveAddons = _.map(this.selectedNewPlan.active_addons, function(o) {
            if (o.selected) return o;
          });

          var currentPlanActiveAddons = _.map(this.currentActivePlan.current_addons, function(o) {
            if (o.selected) return o;
          });

          selectedPlanActiveAddons = _.without(selectedPlanActiveAddons, undefined);
          currentPlanActiveAddons = _.without(currentPlanActiveAddons, undefined);
          if(!this.showAddonsDialog && (!this.isOnboarding && this.currentActivePlan.syncio_plan.id === this.selectedNewPlan.id && _.isEqual(selectedPlanActiveAddons,currentPlanActiveAddons))) {
            return 'disable-events';
          }

          return '';
        },
        allSelectedAddonOff: function() {
            let allOff = true;
            this.selectedNewPlan.active_addons.forEach((feature) => {
                if (feature.selected) {
                    allOff = false;
                }
            });

            return allOff;
        },
        totalPrice: function() {
            if (this.selectedNewPlan != null) {
                return parseFloat(this.selectedNewPlan.price_per_month) + this.totalAddonFees;
            }
            return 0.0;
        },
        currentTotalPrice() {
            if (this.currentActivePlan != null) {
                return parseFloat(this.currentActivePlan.syncio_plan.price_per_month) + this.currentTotalAddonFees;
            }
            return 0;
        },
        currentTotalAddonFees: function () {
            let addonFees = 0.0;
            if (this.currentActivePlan != null && typeof(this.currentActivePlan.active_addons) != 'undefined' && this.currentActivePlan.active_addons.length) {
                this.currentActivePlan.active_addons.forEach((feature) => {
                    if (feature != null && feature.selected) {
                        addonFees += parseFloat(feature.price_per_month);
                    }
                });
                return addonFees;
            }
            return parseFloat(0);
        },
        totalAddonFees: function() {
            let addonFees = 0.0;
            if (this.selectedNewPlan != null && typeof(this.selectedNewPlan.active_addons) != 'undefined' && this.selectedNewPlan.available_addons.length) {
                this.selectedNewPlan.active_addons.forEach((feature) => {
                    if (feature != null && feature.selected) {
                        addonFees += parseFloat(feature.price_per_month);
                    }
                });
                return addonFees;
            }
            return parseFloat(0);
        },
    },
    methods: {
        removeAddon: function(index) {
            this.selectedNewPlan.active_addons[index].selected = false;
            this.$store.commit('plan/SET_SELECTED_NEW_PLAN', this.selectedNewPlan);
        },
        resetPlan: function() {
            let addons = Object.assign([], this.selectedNewPlan.active_addons);
            this.$store.commit('plan/SET_SELECTED_NEW_PLAN_BY_ID', this.currentActivePlan.syncio_plan.id);
            this.selectedNewPlan.active_addons = addons;
            this.$store.commit('plan/SET_SELECTED_NEW_PLAN', this.selectedNewPlan);
        },
        shouldHideNewAddonLineItem: function(newPlanFeature) {
            let matchingCurrentPlanFeature = null;
            $.each(this.currentActivePlan.active_addons, (index, item) => {
                if (item.module_id === newPlanFeature.module_id) {
                    matchingCurrentPlanFeature = item;
                    return;
                }
            });

            return (
                this.selectedNewPlan != null && 
                this.currentActivePlan != null && 
                matchingCurrentPlanFeature != null && 
                matchingCurrentPlanFeature.selected === newPlanFeature.selected &&
                matchingCurrentPlanFeature.price_per_month === newPlanFeature.price_per_month
            );
        },
        shouldCrossOutOldAddonLineItem: function(currentPlanFeature) {
            let matchingNewPlanFeature = null;
            $.each(this.selectedNewPlan.active_addons, (index, item) => {
                if (item.module_id === currentPlanFeature.module_id) {
                    matchingNewPlanFeature = item;
                    return;
                }
            });

            return (
                matchingNewPlanFeature === null ||
                (
                    this.selectedNewPlan != null && 
                    typeof(this.selectedNewPlan.active_addons) != "undefined" &&
                    !matchingNewPlanFeature.selected
                ) ||
                currentPlanFeature.price_per_month != matchingNewPlanFeature.price_per_month
            );
        },
      submitNewPlan(){
          let optionIds = [];
          $.each(this.selectedNewPlan.active_addons, (index, item) => {
              if (typeof(item.selected) != 'undefined'  && item.selected) {
                  optionIds.push(item.module_id);
              }
          });
          UserApiService.submitShopifyNewPlan(this.currentShop.id, this.selectedNewPlan.id, optionIds, this.isOnboarding)
            .then(({ data }) => {
                if (data.success) {
                    if (this.currentShop.platform === 'shopify') {
                        // redirect to the shopify billing approval page.
                        window.location.href = data.charge.confirmation_url;
                    }
                }
            })
      }
    }
}
</script>

<style lang="scss">
.color-grey{
  color: #495057;
}

.color-navy-hover{
  color: #145b78;
}

.plan-cart-summary {
	overflow: hidden;

	.black-light {
		color: #495057;
	}

	.plan-name {
		transform: translateX(-1.5px);
	}

	table {
		width: 100%;
	}

	.base {
		&.plan {
			table {
				.cross-text {
					td {
						padding-bottom: 0px !important;
					}
				}
			}
		}
	}

	h4 {
		.fa-eraser {
			float: right;
		}
	}

	.submit-btn {
		float: right;
		width: 100%;
	}

	.cross-text {
		text-decoration: line-through;
		color: #7c7c7c;

		.plan-name {
			color: #7c7c7c;
		}
	}

	.summary-group {
		font-weight: 700;
		color: #6c6c6c;
	}

	table {
		tr {
			vertical-align: top;
				td {
					&:nth-child(2) {
						text-align: right;
				}

				.plan-reset-x {
					margin-right: 15px;
					color: #999;
				}
			}
		}
	}

	.extra {
		.fa-times {
			color: #eee;
			margin-right: 5px;

			&:hover {
				color: #fa757b;
			}
		}

		.hide {
			display: none;
		}

		table {
			&.empty,
			&.current {
				margin-bottom: 0px;
			}

			&.new {
				margin-bottom: 32px;
			}

			tr {
				td {
					padding-bottom: 0px !important;
				}
			}
		}
	}

	.button {
		width: 100%;
		&.checkout {
			background: none;
			background-color: #88bc51;
			box-shadow: inset 0 1px 0 0 #88bc51, 0 1px 0 0 rgba(22, 29, 37, 0.05), 0 0 0 0 transparent;
			border: .1rem solid #7fb14b;
		}
	}
}

.disable-events {
  opacity: 0.5;
  pointer-events: none
}
</style>